



















import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class CashAuditDialog extends Vue {
  dialogFormVisible: boolean = false;
  formData: any = {
    success: true,
    auditMessage: "string",
    id: ""
  };
  loading: boolean = false;
  rules: any = {
    auditMessage: [
      { required: true, message: "请输入审核消息", trigger: "blur" }
    ]
  };
  show(id: string) {
    this.dialogFormVisible = true;
    this.formData = {
      success: true,
      auditMessage: "",
      id
    };
  }
  submitAudit() {
    (this.$refs["auditForm"] as any).validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        try {
          var res = await this.$ajax.post(
            "/api/services/app/Member/AuditTakeOutLog",
            this.formData
          );
          if (res.data.success) {
            this.dialogFormVisible = false;
            this.$notify.success("审核成功");
            this.$emit("confirm");
          }
        } catch (error) {}
      }
      this.loading = false;
    });
  }
}
