



















































































































import { Component, Vue, Watch } from "vue-property-decorator";

import { articleTypes } from "@/libs/appconst";
import CashAuditDialog from "../member/components/CashAuditDialog.vue";

@Component({
  components: {
    CashAuditDialog
  },
//   过滤器
  filters:{
    //   filterValue(value) {
    //     return Number(value).toLocaleString();
    //   }
  }
})
export default class MemberCashList extends Vue {
  pagerHeader: any = {
    title: "提现列表",
    desc: "提现列表",
    breadcrumb: ["会员管理", "提现列表"]
  };
  loading: boolean = false;
  filter: any = {
    page: 1,
    size: 20,
    memberId: "",
    status: [],
    startDate: null,
    endDate: null,
    keywords: ""
  };
  dateRange: any = [];
  tableData: any = {
    items: [],
    totalCount: 0
  };
    //日期改变   
  timeVoi(value:Date[]){
    if (value != null && value.length == 2) {
        this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss")
        if(value[1].Format('hh')=="00"&&value[1].Format('mm')=="00"&&value[1].Format('ss')=="00"){
            this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59");
            this.dateRange=[this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss"),this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59")]
        }else{
            this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")
            this.dateRange=[this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss"),this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")]
        }
      this.getData()
    } else {
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.getData()
    } 
  }

  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.getData();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.getData();
  }
  async getData() {
    this.loading = true;
    try {
      let res = await this.$ajax.get(
        "/api/services/app/Member/GetTakeOutLogs",
        {
          params: {
             page: this.filter.page,
            size: this.filter.size,
            memberId: this.filter.memberId,
            status: this.filter.status===""?null:this.filter.status,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,
            keywords: this.filter.keywords
          },
        }
      );

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
  search() {
    this.filter.page = 1;
    this.getData();
  }
//   activated() {
//     this.getData();
//   }
  created() {
          // 根据参数知道是否需要根据时间搜索 从统计点击过来
    if(this.$route.query.time==="0"){
        this.getTime(0)
    }else if(this.$route.query.time==="1"){
        this.getTime(1)
    }else if(this.$route.query.time==="2"){
        this.getTime(7)
    }else if(this.$route.query.time==="3"){
        this.getTime(30)
    }
    this.filter.size = this.systemSettings.pageSize;
    if(this.$route.query.status||this.$route.query.status==='0'){
        this.filter.status=Number(this.$route.query.status)
    }
    this.getData();

  }
// 处理传过来的时间数字
    getTime(days:any){
        if(days==1){
           // @ts-ignore
            this.filter.startDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00")
            // @ts-ignore
            this.filter.endDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 23:59:59")
           // @ts-ignore
            this.dateRange=[new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00"),new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 23:59:59")]
        }else{
            // @ts-ignore
            this.filter.startDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00")
            this.filter.endDate =new Date().Format("yyyy-MM-dd 23:59:59")
            // @ts-ignore
            this.dateRange=[new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00"),new Date().Format("yyyy-MM-dd 23:59:59")]
        }
            
    }
  getTypeDesc(type: number) {
    return articleTypes[type];
  }
  get statusOptions() {
    return this.getEnum("MemberTakeOutLogStatus");
  }
  memberItems: any[] = [];
  memberSearchLoading: boolean = false;
  async readerMemberOptions(query: string) {
    this.memberSearchLoading = true;
    try {
      var res = await this.$ajax.post(
        "/api/services/app/Member/QueryPagedList",
        {
          page: 1,
          size: 10,
          keywords: query
        }
      );
      this.memberItems = res.data.result.items;
    } catch (error) {}
    this.memberSearchLoading = false;
  }
  showAuthDialog(id: string) {
    (this.$refs.auditDialog as any).show(id);
  }
}
